<template>
  <div class="index-wrapper">
    <div class="index-main">
      <div class="user-info">
        <i></i>
        <h3>{{wholesalerInfo.name}}</h3>
        <ul>
          <li>{{ wholesalerInfo.market_name }}{{ wholesalerInfo.customCode }}</li>
          <li>主营：{{ wholesalerInfo.desc }}</li>
        </ul>
      </div>
    </div>
    <div class="tabs">
      <div :class="index === currentPurchaser? 'tab active' : 'tab'" v-for="(item, index) in purList" :key="index" @click="choosePur(index)">
        采购商{{index+1}}
      </div>
      <div class="add" @click="addTab">
        +
      </div>
    </div>
    <div class="food-info">
      <div class="search-item">
        <div class="st-search-input">
          <input
            type="text"
            v-model="searchText"
            placeholder="搜索商品名称"
          >
          <div class="input-oper">
            <i
              :class="searchText.length > 0 ? 'active' : ''"
              @click="clearSearch">
            </i>
            <b @click="onSearchBtn"></b>
          </div>
        </div>
      </div>
      <div class="toast">勾选商品后，请选择采购数量</div>
      <div
        class="food-items"
        v-if="productItems.length > 0"
      >
        <van-checkbox-group
          v-model="curShopItems"
          ref="checkboxGroup"
        >
          <van-checkbox
            :name="item"
            v-for="(item, index) in productItems"
            :key="item.id"
            label-disabled
            v-show="searchProduct(item.name)"
            @click="productChange(item, index)"
          >
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
            </template>
            <div class="food-item">
              <div class="food-image">
                <img :src="item.image || productIcon" alt="">
              </div>
              <div class="item-main">
                <div class="item-header">
                  <div class="header-left">
                    <h3>{{ item.name }}</h3>
                  </div>
                  <b>查看进场信息</b>
                </div>
                <ul class="item-body">
                  <li>
                    <p>
                      <s>¥{{ item.price | moneyFormat }}/{{ item.unit }}</s>
                    </p>
                  </li>
                  <li>
                    <van-stepper
                      v-model="item.number"
                      min="0"
                      placeholder="0"
                      @change="productChange(item, index)"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </van-checkbox>
        </van-checkbox-group>
      </div>
    </div>
    <total-price
      :total="total"
      :disabled="disabled"
      @submit="submit"
      v-if="wholesalerInfo"
    />
    <tabbar v-model="active" active-color="#00dfa6" inactive-color="#000" @change="chooseTab">
      <tabbar-item icon="bill-o">售卖</tabbar-item>
      <tabbar-item icon="orders-o">订单</tabbar-item>
    </tabbar>
  </div>
</template>

<script>
import TotalPrice from '../../components/footer/total-price/Index'

import { Tabbar, TabbarItem, Checkbox, CheckboxGroup, Stepper, Toast } from 'vant'
import _ from 'lodash'
import moment from 'moment'
export default {
  name: '',
  components: {
    Tabbar,
    TabbarItem,
    [Checkbox.name]:Checkbox,
    [CheckboxGroup.name]:CheckboxGroup,
    [Stepper.name]:Stepper,
    TotalPrice
  },
  data () {
    return {
      activeIcon: 'https://static.smartracing.cn/images/yz-wholesaler-h5/active-checkbox.png',
      inactiveIcon: 'https://static.smartracing.cn/images/yz-wholesaler-h5/inactive-checkbox.png',
      active: 0,
      currentPurchaser: 0,
      purList: [[]],
      searchText: '',
      wholesalerInfo: {},
      productItems: [],
      productTemplate: [],
      total: 0,
      shopItems: [], // 已选择的商品
      curShopItems: [],
      isFirst: false,
      disabled: true
    }
  },
  mounted () {
    this.$api.users.profiles({
      id: this.$route.query.id
      // id: '5feaab678d6c22107d07330a'
    }).then(res => {
      if (res.data.success) {
        this.wholesalerInfo = res.data.result
        this.getProductItems(this.wholesalerInfo.e_market_shop_id)
        this.getRegisterItems(this.wholesalerInfo.e_market_shop_id)
      } else {
        Toast('查询不到商家信息')
      }
    })
  },
  methods: {
    chooseTab () {
      console.log(this.active)
    },
    choosePur (index) {
      this.shopItems[this.currentPurchaser] = this.curShopItems
      this.purList[this.currentPurchaser] = this.productItems
      this.currentPurchaser = index
      this.productItems = this.purList[index]
      console.log(this.productItems)
      this.curShopItems = this.shopItems[index]
    },
    addTab () {
      console.log('add')
      let goods = JSON.parse(JSON.stringify(this.productTemplate))
      this.purList.push(goods)
      this.shopItems.push([])
    },
    getProductItems (shopId) {
      this.$api.products.saleItems({
        flag: 1,
        page_no: 1,
        page_size: 1000,
        seller_id: shopId
      }).then(res => {
        if (res.status === 200) {
          let items = res.data.data
          // 格式化商品列表
          this.productItems = _.map(items, item => {
            let skus = _.map(item.sku_list, sku => {
              return {
                price: sku.price,
                unit: sku.spec_list[0].spec_name,
                number: sku.spec_list[1].spec_value
              }
            })
            return {
              id: item.goods_id,
              name: item.goods_name,
              price: 0.01,
              unit: '斤',
              image: item.goods_gallery_list[0].small,
              skus: skus,
              number: 0,
              registerInfo: item.shop_registration_vo
            }
          })
          this.productTemplate = JSON.parse(JSON.stringify(this.productItems))
          if (!this.isFirst) {
            this.purList[0] = JSON.parse(JSON.stringify(this.productItems))
            this.shopItems.push([])
          }
          this.isFirst = true
        } else {
          Toast('查询不到相关商品')
        }
      })
    },
    getRegisterItems (id) {
      this.$api.tracing.register.items({
        seller_id: id
      }).then(res => {
        console.log(res.data)
        if (res.status === 200) {
          this.registerItems = res.data
        }
      })
    },
    clearSearch () {
      this.searchText = ''
    },
    onSearchBtn () { },
    searchProduct (text) {
      if (text.indexOf(this.searchText) > -1) {
        return true
      } else {
        return false
      }
    },
    productChange (item) {
      console.log(item)
      this.shopCartInfo()
    },
    shopCartInfo () {
      this.total = 0
      this.curShopItems.length > 0
        ? this.disabled = false
        : this.disabled = true

      // 计算总价
      _.map(this.curShopItems, shopItem => {
        let itemPrice = Number(shopItem.price)
        let itemNumber = Number(shopItem.number)
        if (itemPrice <= 0 || itemNumber <= 0) {
          this.disabled = true
        }
        this.total = Number(this.total) + (itemPrice * itemNumber)
      })
      this.total = this.moneyFormat(this.total)

      // 是否全选
      this.curShopItems.length === this.productItems.length
        ? this.toggleAll = true
        : this.toggleAll = false
    },
    submit () {
      let items = _.map(this.curShopItems, item => {
        let n = _.findIndex(this.registerItems, registerItem => registerItem.goods_id === item.id)
        return {
          goodsId: item.id,
          goodsName: item.name, // 商品名称
          goodsImage: item.image, // 商品图片
          goodsPrice: Number(item.price), // 商品单价
          buyNumber: Number(item.number), // 商品数量
          unit: item.unit, // 规格名称

          // 溯源信息
          time: this.registerItems[n].create_time
            ? moment.unix(this.registerItems[n].create_time).format('YYYY-MM-DD HH:mm:ss')
            : '',
          goodsType: this.registerItems[n].kind, // 产品种类
          licensePlateNumber: this.registerItems[n].license_plate_number, // 运输车牌号
          placeOfOrigin: this.registerItems[n].place_of_origin, // 产地
          numberOfBranches: this.registerItems[n].number_of_branches, // 进场数量
          inspectionAndQuarantine: this.registerItems[n].inspection_and_quarantine, // 产地检疫证号
          inspectionAndQuarantineList: this.registerItems[n].inspection_and_quarantine_list, // 产地检疫证号图片
          bno: this.registerItems[n].sn, // 批次号
          productMajorType: this.registerItems[n].type // 产品大类
        }
      })
      let orderInfo = {
        merchId: this.$route.query.id, // 批发商id
        orderMoney: Number(this.total), // 总金额
        userId: '',
        buyerMobile: '',
        data: items // 商品及溯源信息
      }
      // console.log(orderInfo)
      this.addOrder(orderInfo)
    },
    addOrder (orderInfo) {
      // orderInfo.userId = this.userInfo.id
      // orderInfo.buyerMobile = this.userInfo.mobile
      this.$api.orders.add(orderInfo).then(res => {
        if (res.data.success) {
          this.$store.dispatch('setOrderInfo', {})
          this.$router.push({
            name: 'WaitPaid',
            query: {
              orderId: res.data.map.orderId,
              merchId: this.$route.query.id
            }
          })
        } else {
          Toast('订单提交失败')
        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
